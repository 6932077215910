<template>
  <div>
    <b-container>
    <table>
      <thead style="background-color:#93278f!important">
        <tr style="background-color:#93278f!important">
          <th>
            Pos
          </th>
          <th>
            Name
          </th>
          <th>
            Nat
          </th>
          <th style='text-transform: capitalize;' class="TextCen">
            {{data.value_1_head}}
          </th>
          <th class="mobNo TextCen">
            {{data.oom_header}}
          </th>
          <th class="mobNo"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(stats, index) in data.oom.oom_entry.slice(0,11)" :key="index">
          <td class="posColour">
            {{ stats.pos }}
          </td>
          <td>
            <a :href="'/playerprofile/' + stats.playerno" class="OOMstatsColour">
              {{ stats.name }} 
              <span v-if="stats.lb_sponsored === 'Y'">
                <img class="event-lb-sponsor-logo" :src="data.event_lb_sponsor_logo">
              </span>
            </a>
          </td>
          <td>
            <span>
              <img
                :class="flag"
                :src="
                  (config.VUE_APP_FLAG_URL +
                    stats.nationality)
                    | lowercase
                "
              />
            </span>
          </td>
          <td class="TextCen">
            {{ stats.oom_value }}
          </td>
          <td
            class="mobNo"
            v-if="
              stat === 'ooms-oom-FT' ||
                stat === 'ooms-oom-FZ' ||
                stat === 'ooms-oom-LI'
            "
          >
            {{ stats.tournaments_played }}
          </td>
          <td v-else class="mobNo TextCen">
            {{ stats.played }}
          </td>
          <td class="mobNo">
            <a :href="'/playerprofile' + '/' + stats.playerno" class="moreInfo">
              More Info
              <span class="rightArrow">
                <font-awesome-icon
                  class="icon rightArrow"
                  :icon="['fa', 'chevron-right']"
                />
              </span>
            </a>
          </td>
        </tr>
        <template v-if="isHidden">
          <tr v-for="(stats, index) in data.oom.oom_entry.slice(11,1000)" :key="index">
            <td class="posColour">
              {{ stats.pos }}
            </td>
            <td>
              <a :href="'/playerprofile/' + stats.playerno" class="OOMstatsColour">
                {{ stats.name }} 
                <span v-if="stats.lb_sponsored === 'Y'">
                  <img class="event-lb-sponsor-logo" :src="data.event_lb_sponsor_logo">
                </span>
              </a>
            </td>
            <td>
              <span>
                <img
                  :class="flag"
                  :src="
                    (config.VUE_APP_FLAG_URL +
                      stats.nationality)
                      | lowercase
                  "
                />
              </span>
            </td>
            <td class="TextCen">
              {{ stats.oom_value }}
            </td>
            <td
              class="mobNo"
              v-if="
                stat === 'ooms-oom-FT' ||
                  stat === 'ooms-oom-FZ' ||
                  stat === 'ooms-oom-LI'
              "
            >
              {{ stats.tournaments_played }}
            </td>
            <td v-else class="mobNo TextCen">
              {{ stats.played }}
            </td>
            <td class="mobNo">
              <a :href="'/playerprofile' + '/' + stats.playerno" class="moreInfo">
                More Info
                <span class="rightArrow">
                  <font-awesome-icon
                    class="icon rightArrow"
                    :icon="['fa', 'chevron-right']"
                  />
                </span>
              </a>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    </b-container>
    <div class="seeMore" v-on:click="isHidden = !isHidden">
      <template v-if="!isHidden">
        <div class="SeeMoreText">
          See More
        </div>
      </template>
      <template v-else>
        <div class="SeeMoreText">
          See Less
        </div>
      </template>
    </div>
    <div class="middleAd-above-vid">
        <!-- <span class="advertCen">ADVERT</span>
        <Ads class='mobHide' :page="'home'" :adNum="'third'" :mob='false' :side='false'/>   -->
    </div>
  </div>
</template>

<script>
export default {
  name: "statsTable",
  props: ["id", "stat", "data", "config"],
  data() {
    return {
      noData: "noData",
      flag: "flag",
      cell: "cell",
      fields: "fields",
      name: "name",
      isHidden: false
    };
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }
      return value.toLowerCase() + ".svg";
    },
  },
};
</script>

<style scoped>
span.rightArrow {
  margin-left: 10px;
}
td.posColour {
  font-weight: bold;
  color: #f8635f!important;
}
.TextCen {
  text-align: center;
}
span.advertCen {
  text-align: center;
  display: block;
  margin-top: 50px;
  font-weight: bold;
  font-size: 20pt;
}
.seeMore {
  background: #93278f;
  text-align: center;
  margin: auto;
  padding: 8px;
  width: 200px;
  margin-top: 30px;
  height: 30px;
}
.seeMore:hover {
  background: #f8635f;
  text-align: center;
  margin: auto;
  padding: 8px;
  width: 200px;
  margin-top: 30px;
}
.SeeMoreText {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  text-transform: capitalize;
  line-height: 13px;
}
a.moreInfo {
  color: #6C6C6C!important;
  font-weight: bold;
  font-size: 13px;
}
a.moreInfo:hover {
  color: #f8635f!important;
  font-weight: bold;
  font-size: 13px;
  text-decoration: none
}
a.OOMstatsColour {
  color: #6C6C6C!important;
  font-weight: bold;
  font-size: 13px;
}
a.OOMstatsColour:hover {
  color: #f8635f!important;
  font-weight: bold;
  font-size: 13px;
  text-decoration: none;
}
.event-lb-sponsor-logo {
  /* width: 15px; */
  width: 31px;
  position: relative;
  left: 15px;
}
table > tbody > tr > td {
  padding: 0.75rem;
  vertical-align: top;
  line-height: 1.42857143;
  border-right: none !important;
  cursor: pointer;
  font-size: 13px;
  color: #6C6C6C;
}
table > thead > tr > th {
  background-color: #93278f;
  color: #fff;
  position: sticky;
  position: -webkit-sticky;
  color: #ffffff !important;
  padding: 0.75rem;
  font-size: 13px;
  font-weight: 500;
}
table {
  width: 100%;
}
.flag {
  width: 30px;
  box-shadow: 0 0 3px #555;
}

tr {
  /* background-color: rgba(0, 0, 0, 0.05); */
  border-bottom: 1px solid #ebebeb;
}
@media only screen and (max-width: 990px) {
  .seeMore {
    background: #93278f;
    text-align: center;
    margin: auto;
    padding: 8px;
    width: 100%;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 768px) {
  .mobInfo {
    width: 80%;
    text-align: left;
  }
}
@media only screen and (max-width: 480px) {
  td.mobNo {
    display: none;
  }
  th.mobNo {
    display: none;
  }
  .tCont {
    padding: 70px 20px 20px;
    background-color: white;
    border-radius: 10px;
    margin-top: 0px;
  }
}
</style>
