<template>
  <div>
    <b-container class="newslist" v-if="this.$route.name === 'News'">
      <div>
        <b-row>
          <b-col>
            <h3 class="secTitle">
              Latest news
            </h3>
          </b-col>
          <b-col>
            <b-nav-item class="viewAll" to="/morenews">
              View All
            </b-nav-item>
          </b-col>
        </b-row>
        <IntaNews :cat="'8'" :feat="'true'" :config="config" />
      </div>
      <div>
        <IntaNews :cat="'8'" :feat="'false'" :config="config"/>
      </div>
      <div>
        <h3 class="secTitle">
          Highlights
        </h3>
        <IntaNews :cat="'5'" :config="config"/>
      </div>
      <div>
        <h3 class="secTitle">
          Featured News
        </h3>
        <IntaNews :cat="'4'" :config="config"/>
      </div>
    </b-container>
    <b-container class="newslist" v-if="this.$route.name === 'thescore'">
      <div>
        <h3 class="secTitle">
          The Score
        </h3>
        <IntaNews :cat="'10'" :feat="'false'" :config="config"/>
      </div>
    </b-container>
    <b-container class="newslist" v-if="this.$route.name === 'morenews'">
      <div>
        <h3 class="secTitle">
          All News
        </h3>
        <IntaNews :cat="'10,8,5,6'" :config="config"/>
      </div>
    </b-container>
  </div>
</template>

<script>
import IntaNews from "@/components/interNews.vue";
export default {
  name: "posts",
  components: {
    IntaNews,
  },
  props: ["config"],
  data() {
    return {
      posts: [],
      featHome: "false",
    };
  },
  metaInfo() {
    return {
      title: 'News',
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
};
</script>

<style scoped>
::v-deep .viewAll > a {
  color: #fff!important;
} 
.viewAll {
  background: #93278f;
  border: none;
  color: #fff!important;
  width: 100px;
  border: 1px solid #E0E0E0;
  font-weight: bold;
  font-size: 11px;
  padding: 0;
  text-transform: capitalize;
  height: 35px;
  line-height: 6px;
  text-align: center;
  margin-top: 5.3em;
  float: right;
  line-height: 20px!important;
  display: block;
}
.viewAll:hover {
  background: #f8635f;
  border: none;
  color: #fff!important;
  width: 100px;
  border: 1px solid #E0E0E0;
  font-weight: bold;
  font-size: 11px;
  padding: 0;
  text-transform: capitalize;
  height: 35px;
  line-height: 6px;
  text-align: center;
  margin-top: 5.3em;
  float: right;
  line-height: 20px!important;
  display: block;
}
.seeAllNews {
  text-align: center;
  background: #f8635f;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 300px;
  float: right;
  margin-top: 80px;
}
.seeAllNews:hover {
  text-align: center;
  background: #93278f;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 300px;
  float: right;
  margin-top: 80px;
}
.newslist {
  margin-top: 2em;
  padding-bottom: 50px;
}

.secTitle {
  color: #6C6C6C;
  font-weight: bold;
  font-size: 21px;
  margin-top: 0;
  padding-top: 3em;
  padding-bottom: 1em;
  text-transform: capitalize;
 
}
</style>
