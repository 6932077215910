<template>
  <div>
      <PlayerProfileTopInformation 
        :config="config"
        :season="season"
        :refno="refno"
        :playerinfo="playerinfo"
        :enhancedPlayerInfo="enhancedPlayerInfo"
        :enhancedPlayerOOM="enhancedPlayerOOM"
        :DEFIMAGESAMS3="DEFIMAGESAMS3"
        :show_name_pronounce="show_name_pronounce"
        :name_pronounce="name_pronounce"
      />
    <div class="playerList">
      <PlayerProfileStats 
        :config="config"
        :season="season"
        :playerinfo="playerinfo"
        :enhancedPlayerInfo="enhancedPlayerInfo"
        :enhancedPlayerOOM="enhancedPlayerOOM"
        :isMobile="isMobile"
        :PrizeMoney="PrizeMoney"
        :careerMoney="careerMoney"
        
      />
    </div>
  </div>
</template> 

<script>
import apiCall from "@/services/api.js";
import PlayerProfileStats from "@/components/playerProfileStats.vue"
import PlayerProfileTopInformation from "@/components/playerProfileTopInformation.vue"
export default {
  name: "playerprofile",
  components: {
    PlayerProfileStats,
    PlayerProfileTopInformation
  },
  props: ["refno", "season", "config"],
  data() {
    return {
      playerinfo: [],
      enhancedPlayerInfo: [],
      enhancedPlayerOOM: '',
      show_name_pronounce: false,
      name_pronounce: "",
      careerMoney: '',
      PrizeMoney:''
    };
  },
  metaInfo() {
    return {
      title: this.enhancedPlayerInfo.full_name,
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
  filters: {
    lowercase: function(value) {
      if (!value) {
        return "";
      }

      return value.toLowerCase() + ".svg";
    },
  },
  methods: {
    isMobile() {
        if( screen.width <= 760 ) {
            return true;
        }
        else {
            return false;
        }
    },
  },
  async mounted() {
    try {
      // First call, await for call to return
      let first_call = await apiCall.playersInfo(this.refno);
      this.playerinfo = first_call.data;

      var m = this.playerinfo.STATISTICS.STATISTIC
      m.forEach(value=>{
        value.CODE
        if (value.CODE == 'LA01') {
            this.careerMoney = value.VALUE
            console.log(this.careerMoney)
        } else if (value.CODE == 'LA02') {
            this.PrizeMoney = value.VALUE
            console.log(this.PrizeMoney)
        } 
      })
      this.DEFIMAGESAMS3 = first_call.data.GENERAL.DEFIMAGESAMS3;
      this.playerinfoBestMoney = first_call.data.STATISTICS.STATISTIC[0].VALUE;
      this.playerinfoCareerMoney = first_call.data.STATISTICS.STATISTIC[1].VALUE;
      if (first_call.data.MEMBER.PRONOUNCIATIONS.PRONOUNCIATION) {
        console.log("player_pronounce start");
        let player_pronounce =
          first_call.data.MEMBER.PRONOUNCIATIONS.PRONOUNCIATION;
        if (!Array.isArray(player_pronounce)) {
          player_pronounce = [player_pronounce];
        }
        if (player_pronounce[0].PHOTO_IMAGE.length > 0) {
          this.show_name_pronounce = true;
          this.name_pronounce =
            "https://tms-images.ams3.cdn.digitaloceanspaces.com/sst" + "/members/" +
            this.refno +
            "/" +
            player_pronounce[0].PHOTO_IMAGE;
        }
      }
      this.loading = false;
      // Second call, await for call to return
      let second_call = await apiCall. playersEnhance(this.refno, this.season);
      this.enhancedPlayerInfo = second_call.data;
      this.enhancedPlayerOOM = second_call.data.ooms.tourn_details[1].pos;
      this.loading = false;
    } catch (error) {
      console.log("error in await calls");
      console.log(error);
      throw error;
    }
  }
};
</script>
