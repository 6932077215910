<template>
    <div class="backgroundGreen">
        <b-container>
            <b-navbar toggleable="lg" type="dark" variant="info">
                <h2 class="welcomeText">Welcome <span class="name">{{Forename}}</span> to your Sunshine Tour</h2>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <b-nav-item class="fanNavLinks" to="/fanZone">My Tour</b-nav-item>
                        <b-nav-item class="fanNavLinks" to="/myplayers">My Players</b-nav-item>
                        <b-nav-item class="fanNavLinks" to="/videohub">Video Hub</b-nav-item>
                        <b-nav-item class="fanNavLinks" to="/myprofile">My Profile</b-nav-item>
                        <b-nav-item class="fanNavLinks" @click="signOut()">Sign Out</b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </b-container>
    </div>
</template>

<script>
// import apiCall from "@/services/api.js";
export default {
    name:"Fan Zone Header",
    props: ["config", "loginForename"],
    components: {
    },
    data(){
        return {
            wpInfo:[],
            wpInfoTitle: '',
            Forename: ''
        }
    },
    metaInfo() {
        return {
            title: 'Fan Zone',
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
            ]
        }
    },
    methods: {
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
        ForenameData() {
            let Forname = localStorage.getItem("Forname");
            this.Forename = Forname;
            console.log(this.Forename)
        },
        gohome: function(){
        // this.$router.push({ name: "home" });
            window.location.href = '/'
        // console.log('fan')
        },
        signOut() {
            // const ctx = this
            // this.gohome()
            var myItem = localStorage.getItem('FavPlayerName');
            localStorage.clear();
            localStorage.setItem('FavPlayerName',myItem);
            this.gohome()
        }
    },
    mounted() {
        this.ForenameData();
    }
    // created() {
    // const json = "wp/v2/pages?slug=" + this.$route.name + "&randomadd=" + new Date().getTime();
    // apiCall
    //   .wpInfo(json)
    //   .then(({ data }) => {
    //     this.wpInfo = data;
    //     console.log("this.wpInfo -- ")
    //     console.log(this.wpInfo)
    //     this.wpInfoTitle = data[0].title.rendered;
    //     console.log("this.wpInfoTitle")
    //     console.log(this.wpInfoTitle)
    //     this.loading = false;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     throw error;
    //   });
    // },
}
</script>

<style scoped>
::v-deep li.fanNavLinks > a.nav-link.router-link-exact-active.router-link-active {
    color: #f8635f!important;
}
::v-deep li.fanNavLinks:hover > a {
    color: #f8635f!important;
}
::v-deep li.fanNavLinks> a {
    color: #fff!important;
    font-size: 12px;
}
div#nav-collapse {
    position: absolute;
    right: 0;
    padding-right: 22px;
}
span.name {
    color: #f8635f;
    font-weight: bold;
}
h2.welcomeText {
    font-size: 14px;
    color:#fff;
    margin-bottom: 0;
}
.backgroundGreen {
    background-color: #93278f;
}
::v-deep .bg-info {
    background-color: #93278f !important;
    height: 50px;
    padding-left: 0;
}
@media screen and (max-width: 990px) {
    ::v-deep .bg-info {
        height: auto!important;
    }
    ::v-deep div#nav-collapse {
        position: relative!important;
        right: auto!important;
        padding-right: 22px;
    }
    ::v-deep .navbar-toggler {
        padding: 0.25rem 0.75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        margin-top: -3px;
        display: contents;
    }
}
</style>