<template>
  <b-container>
    <b-table :fields="fields" :items="stats" show-empty> </b-table>
  </b-container>
</template>

<script>
export default {
  name: "playerThisSeason",
  props: ["stats", "config"],
  data() {
    return {
      fields: [
        { key: "DATE", label: "Date" },
        { key: "NAME", label: "Name" },
        { key: "POSITION", label: "Pos" },
        { key: "ROUNDS", label: "Rounds", class: 'RoundsRemove' },
        { key: "SCORE", label: "Score" },
        { key: "CURRENCY", label: "Currency", class: 'RoundsRemove'},
        { key: "WINNINGS", label: "Winnings" }
      ]
    };
  }
};
</script>

<style scoped>
::v-deep thead>tr>th{
  text-align: left;
  padding: 8px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  border-top: 0px solid #dee2e6!important;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0.75rem;
  padding-left: 5px;
}
::v-deep thead>tr{
  background-color: #4A214D!important;
  color: white;
}
::v-deep tbody>tr>td{
  text-align: left;
  padding: 8px;
  /* border-top: 1px solid #fff; */
  font-size: 13px;
  color: #6C6C6C;
}
::v-deep tbody>tr{
  /* background-color: rgba(0,0,0,.05); */
}

::v-deep th:first-child{
  text-align: left;
}
::v-deep td:first-child{
  text-align: left;
  color: #f8635f;
  font-weight: 500;
  font-size: 13px;
}

::v-deep td:last-child{
  color: #3C3C3C;
  font-weight: 600;
}
@media only screen and (max-width: 768px) {
  ::v-deep th.RoundsRemove {
    display: none;
  }
  ::v-deep td.RoundsRemove {
    display: none;
  }
}
</style>
