<template>
    <div class="MyprofileBack">
        <FanZoneHeader 
            :loginForename='loginForename'
        />
        <b-container class="myProfile">
            <div class="spaceTop">
                <h1 class="Title">My Profile</h1>
            </div> 
            <h3 class="messageText" v-if="status == 'OK'">{{message}}</h3>
            <b-form @submit.prevent="editUser" class="modalCLass">                
                <b-row>
                    <b-col md="6" sm="12" v-for="(field, index) in this.fanUserData.fields" :key="index"  v-show="field.module_name == 'Personal' && field.active == '1'">
                        <p></p>
                        <template v-if="field.type_display == 'datepick'">
                            <b-form-group
                                id="input-group-2"
                                :label="field.label"
                                label-for="input-2"
                                class="formFont"
                            >
                                <div class="outerFromSign">
                                    <b-form-input
                                        type="date"
                                        :disabled="(field.key == '1')"
                                        id="input-2"
                                        v-model="submitFields[field.name]"
                                        class="inputFontSize"
                                    ></b-form-input>
                                </div>
                            </b-form-group>
                        </template>
                        <template v-else-if="(field.type_display == 'select' && field.name !== 'country' && field.name !== 'nationality')">
                            <b-form-group
                                id="input-group-2"
                                :label="field.label"
                                label-for="input-2"
                                class="formFont"
                            >
                                <div class="outerFromSign">
                                    <b-form-select 
                                        :disabled="(field.key == '1')"
                                        v-model="submitFields[field.name]"
                                    >
                                        <b-form-select-option 
                                            v-for="(opt, optIdx) in selectOpts[field.table_name + '-' + field.name]"
                                            :key="optIdx"
                                            :value="opt.code"
                                            >{{opt.desc}}
                                        </b-form-select-option>
                                    </b-form-select>
                                </div>
                            </b-form-group>
                        </template>
                        <template v-else-if="(field.type_display == 'select' && field.name == 'nationality')">
                            <b-form-group
                                id="input-group-2"
                                :label="field.label"
                                label-for="input-2"
                                class="formFont"
                            >
                                <div class="outerFromSign">
                                    <b-form-select 
                                        :disabled="(field.key == '1')"
                                        v-model="submitFields[field.name]"
                                    >
                                        <b-form-select-option 
                                            v-for="(opt, optIdx) in countryList"
                                            :key="optIdx"
                                            :value="opt.code"
                                            >{{opt.desc}}
                                        </b-form-select-option>
                                    </b-form-select>
                                </div>
                            </b-form-group>
                        </template>
                        <template v-else-if="(field.type_display == 'select' && field.name == 'country')">
                            <b-form-group
                                id="input-group-2"
                                :label="field.label"
                                label-for="input-2"
                                class="formFont"
                            >
                                <div class="outerFromSign">
                                    <b-form-select 
                                        :disabled="(field.key == '1')"
                                        v-model="submitFields[field.name]"
                                    >
                                        <b-form-select-option 
                                            v-for="(opt, optIdx) in countryList"
                                            :key="optIdx"
                                            :value="opt.code"
                                            >{{opt.desc}}
                                        </b-form-select-option>
                                    </b-form-select>
                                </div>
                            </b-form-group>
                        </template>
                        <template v-else-if="(field.type_display == 'checkbox')">
                            <b-form-group
                                id="input-group-2"
                                :label="field.label"
                                label-for="input-2"
                                class="formFont"
                            >
                                <div class="outerFromSign">
                                    <b-form-checkbox
                                        v-model="submitFields[field.name]"
                                        :disabled="(field.key == '1')"
                                    >
                                        {{ (field.label + ((field.mandatory == '1') ? ' *' : '')) }}
                                    </b-form-checkbox>
                                </div>
                            </b-form-group>
                        </template>
                        <template v-else>
                            <b-form-group
                                id="input-group-2"
                                :label="field.label"
                                label-for="input-2"
                                class="formFont"
                            >
                                <div class="outerFromSign">
                                    <b-form-input
                                        :type="field.type_display"
                                        :inputmode="field.input"
                                        :disabled="(field.key == '1')"
                                        id="input-2"
                                        v-model="submitFields[field.name]"
                                        class="inputFontSize"
                                    ></b-form-input>
                                </div>
                            </b-form-group>
                        </template>
                    </b-col>
                </b-row>
                <b-button type="submit" variant="primary" class="SignUp">Save</b-button>
            </b-form>
        </b-container>
    </div>
</template>

<script>
// import apiCall from "@/services/api.js";
import axios from "axios"
import FanZoneHeader from '@/ocs/fanzone/components/fanZoneHeader.vue'
export default {
    name:"My Profile",
    props: ["config"],
    components: {
        FanZoneHeader
    },
    data(){
        return {
            wpInfo:[],
            wpInfoTitle: '',
            loginForename: '',
            initalFanData: '',
            initalFanDataFull:{},
            options: [
                { value: 'M', text: 'Male' },
                { value: 'F', text: 'Female' },
            ],  
            email: "",
            password:"",
            email_string: "",
            post_string: "",
            UserEmail: this.email,
            FullDOB: '',
            fanUserData: {},
            selectOpts: {},
            oldData: {},
            FulUserAll:[],
            FullUserDate: {},
            submitFields: {},
            countryList: [],
            status: ''
        }
    },
    metaInfo() {
        return {
            title: 'Fan Zone',
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.description },
            ]
        }
    },
    methods: {
        getDateFromTimestamp(input, strFormat) {
        const dateObj = new Date(input);
        const days = this.getDaysList(3);
        const months = this.getMonthsList(true);

        let output = '';

        if (strFormat) {
            output += String(dateObj.getFullYear()) + '-';
            output += String(dateObj.getMonth() + 1).padStart(2, '0') + '-';
            output += String(dateObj.getDate()).padStart(2, '0');
        }
        else {
            output += days[dateObj.getDay()] + ' ';
            output += dateObj.getDate() + ' ';
            output += months[dateObj.getMonth()] + ' ';
            output += String(dateObj.getHours()).padStart(2, '0') + ':';
            output += String(dateObj.getMinutes()).padStart(2, '0');
        }

        return output;
        },
        getMonthsList(abbr, upper) {
        let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        months = months.map((val) => {
            if (abbr)
            val = val.substring(0, 3);

            if (upper)
            val = val.toUpperCase();

            return val;
        });

        return months;
        },
        getDaysList(abbr, upper, monFirst) {
        let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        if (monFirst)
            days.push(days.shift());

        days = days.map((val) => {
            if (abbr)
            val = val.substring(0, 3);

            if (upper)
            val = val.toUpperCase();

            return val;
        });

        return days;
        },
        getFormattedDate(inpDate, config) {
        const dateFmt = (this.appConfig.dateFormat || 'dmy').split('');
        const dateOut = [];
        const outDelim = (typeof config.delimiter != 'undefined') ? config.delimiter : '/';

        let dateObj = {};

        if (inpDate instanceof Date)
            dateObj = inpDate;
        else if (inpDate.length == 10 && inpDate.includes('-'));
            dateObj = new Date(inpDate);

        for (const i of dateFmt) {
            let dateItem = '';

            switch (i) {
            case 'd': {
                if (config.ordinalDate) {
                dateItem = this.getOrdinalString(dateObj.getDate());
                }
                else {
                if (config.leadZeros)
                    dateItem = String(dateObj.getDate()).padStart(2, '0');
                else
                    dateItem = String(dateObj.getDate());
                }

                break;
            }
            case 'm': {
                if (config.monthFormat == 'name') {
                dateItem = this.getMonthsList()[dateObj.getMonth()];
                }
                else if (config.monthFormat == 'number') {
                if (config.leadZeros)
                    dateItem = String(dateObj.getMonth() + 1).padStart(2, '0');
                else
                    dateItem = String(dateObj.getMonth() + 1);
                }

                break;
            }
            case 'y': {
                if (config.abbrYear)
                dateItem = String(dateObj.getYear());
                else
                dateItem = String(dateObj.getFullYear());

                break;
            }
            }

            dateOut.push(dateItem);
        }

        return dateOut.join(outDelim);
        },
        getOrdinalString(input, supScript) {
        if (isNaN(input))
            return input;
        else
            input = parseInt(input);

        const j = input % 10;
        const k = input % 100;

        let ord = 'th';

        if (j == 1 && k != 11)
            ord = 'st';
        else if (j == 2 && k != 12)
            ord = 'nd';
        else if (j == 3 && k != 13)
            ord = 'rd';

        if (supScript)
            return input + '<sup>' + ord + '</sup>';
        else
            return input + ord;
        },

        userData() {
            // var UserInfo = JSON.parse(localStorage.getItem("UserData"));
            // this.FullUserDate = UserInfo
            // var userAll = JSON.parse(localStorage.getItem("UserDataFieldsAll"));
            // this.UserFieldItems = userAll

            let fan_user_data = JSON.parse(localStorage.getItem("fan_user_data"))
            this.fanUserData = fan_user_data
            // console.log("this.fanUserData")
            // console.log(this.fanUserData)

        },

        getFieldValue(fieldName) {
            let output = '';

            for (const i of this.fanUserData.fields) {
                if (i.table_name != 'users_' + this.config.VUE_APP_FAN_DATA_BASE)
                    continue;

                if (i.name == fieldName) {
                    const fieldVal = this.fanUserData[fieldName];

                    if (i.type_display == 'datepick')
                        output = this.getDateFromTimestamp(parseInt(fieldVal) * 1000, true);
                        // output = fieldVal;
                    else
                        output = fieldVal;

                    break;
                }
            }

            return output;
        },

        setFieldValues() {
            // set select options
            for (const i of this.fanUserData.fields) {
                if (i.extra_info.options) {
                    const opts = i.extra_info.options.split(',');
                    const optsArr = [];

                    for (const j of opts) {
                        const kvp = j.split('|~');

                        optsArr.push({'code': kvp[0], 'desc': kvp[1]});
                    }

                    this.selectOpts[i.table_name + '-' + i.name] = optsArr;
                }
            }

            for (const i in this.fanUserData) {
                if (i == 'fields')
                    continue;

                const fieldVal = this.getFieldValue(i);

                this.oldData[i] = fieldVal;
                this.submitFields[i] = fieldVal;
            }
        },
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }
            else {
                return false;
            }
        },
        getCountrys() {
            axios
            .get(
                "https://ocs-assets.ams3.cdn.digitaloceanspaces.com/countries-list.json?randomadd=" + new Date().getTime()
            )
            .then((response) => {
                this.countryList = response.data;
            });
        },
        getObjArray(input) {
        let output = [];

        if (Array.isArray(input))
            output = input;
        else
            output = [input];

        return output;
        },
        jsonValue(input) {
        let output = '';

        if (typeof input == 'string')
            output = input.trim();
        else if (['number','boolean'].includes(typeof input))
            output = input;

        return output;
        },
        editUser: function(e) {
            e.preventDefault();
            // let array = []
            let post_string = ''
            let UserEmail = localStorage.getItem("EmailKey")

            const formData = [];
            for (const i in this.submitFields) {
                if (this.submitFields[i] != this.oldData[i] || i == 'email') {
                    formData.push({
                        'table': 'users_' + this.config.VUE_APP_FAN_DATA_BASE ,
                        'name': i,
                        'value': this.submitFields[i],
                    });
                }
            }
            // console.log(formData);
            if (formData.length == 1) {
                this.showAlert('Nothing to Save', 'You have not yet made any changes to your profile');
                return;
            }

            for (let i = 0; i < formData.length; i++) {
                post_string += 'fields[' + i + '][table]=' + formData[i].table + '&';
                post_string += 'fields[' + i + '][name]=' + formData[i].name + '&';
                if (formData[i].name == 'dob')
                    post_string += 'fields[' + i + '][value]=' + Math.floor(new Date(formData[i].value).getTime() / 1000) + '&';
                else
                    post_string += 'fields[' + i + '][value]=' + formData[i].value + '&';
            }

            this.email_string = "email=" + encodeURIComponent(btoa(UserEmail)) + "&"
            post_string += this.email_string
            console.log("post_string")
            console.log(post_string)
            // console.log("post_string")
            // console.log(post_string)
            // console.log("array")
            // console.log(array)
            // const ctx = this
            axios
                .post(
                this.config.VUE_APP_FAN_MARKETING_URL + "tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&action=edit_user&" + post_string + "access_key=" + this.config.VUE_APP_FAN_KEY + "&mc_host=" + this.config.VUE_APP_FAN_MC_HOST,
                post_string, {
                    headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
            .then((response) => {
                this.status = response.data.status;
                if (this.status == 'OK') {
                    this.message = 'Profile updated successfully'
                }
                setTimeout(function () {
                    this.message = ''
                }.bind(this), 5000)
            })
        },
    },
    mounted() {
        let fan_user_data = localStorage.getItem("fan_user_data")
        this.fanUserData = fan_user_data
        // console.log("this.fanUserData")
        // console.log(this.fanUserData)
        let post_string = localStorage.getItem("Email")
        axios
            .post(
                this.config.VUE_APP_FAN_MARKETING_URL + "tour=" + this.config.VUE_APP_FAN_DATA_BASE + "&action=select&" + localStorage.getItem("Email") + "access_key=" + this.config.VUE_APP_FAN_KEY + "&mc_host=" + this.config.VUE_APP_FAN_MC_HOST,
                post_string, {
                    headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
            .then((response) => {

                this.fanUserData = response.data;
                this.AllData = response.data.fields.field;
                console.log("this.Userdata")
                console.log(this.Userdata)

                const fieldKey = 'users_' + this.config.VUE_APP_FAN_DATA_BASE;
                const userFields = this.getObjArray(this.AllData);

                this.fanUserData['fields'] = userFields;

                for (const i of userFields) {
                if (i.table_name == fieldKey)
                    this.fanUserData[i.name] = this.jsonValue(response.data.user[fieldKey + '-' + i.name]);
                }

                localStorage.setItem('fan_user_data', JSON.stringify(this.fanUserData));


                this.userData();
                this.getCountrys();
                this.loginForename = response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-forename']
                this.initalFanData = response.data;
                // console.log("this.initalFanData 224")
                // console.log(this.initalFanData)
                this.initalFanDataFull = response.data.fields.field;
                this.setFieldValues()
                this.userTitle = response.data.user['users_' + this.config.VUE_APP_FAN_DATA_BASE + '-title']
                // this.setFieldValues();
            })
    },
}
</script>

<style scoped>
h3.messageText {
    color: #6C6C6C;
    text-align: center;
    font-size: 15pt;
    margin-bottom: 30px;
}
::v-deep .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + -3px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 13px!important;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 0px solid #ced4da;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 34px!important;
}
.MyprofileBack {
    background-color: #F2F2F2!important;
    padding-bottom: 30px;
}
::v-deep .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
::v-deep label {
    width: 100%;
    color: #6C6C6C;
    font-size: 12px;
    font-weight: 300;
}
::v-deep .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + -3px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 0px solid #ced4da;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.spaceTop {
    margin-top: 30px;
}
h1.Title {
    color: #6c6c6c;
    font-weight: 700;
    font-size: 20px;
    text-transform: capitalize;
    padding-bottom: 25px;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 30px;
}
.whiteBlock {
    padding-top: 50px;
    padding-bottom: 50px;
}
.GreyBlock {
    padding-top: 50px;
    padding-bottom: 50px;
}
.SecondSection {
    background-color: #F2F2F2;
}
.sectionBackground {
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    background-position: center;
}
.SponRow {
    margin: auto;
    text-align: center;
    /* display: table; */
}
::v-deep .conHeader.rowTwoContact.col-lg-6 > p {
    margin-bottom: 0px;
}
.rowTwoContact {
    margin-top: 60px;
}
.contactMargin {
    margin-top: 50px;
    margin-bottom: 50px;
}
.contactBack {
    background-color: #F0F0F0;
}
.contactuss {
    display: none;
}
::v-deep p {
    color: #6C6C6C;
    font-size: 13px;
    line-height: 1.8;
}
::v-deep .conHeader > p > strong {
    color: #93278f;
    font-size: 14px;
   
}
::v-deep .form-control {
    font-size: 13px;
    /* color: #D0D0D0; */
    border-radius: 0px;
}
::v-deep .btn-primary {
    color: #fff;
    background-color: #93278f;
    border-color: #93278f;
    border-radius: 0px;
    font-size: 11px;
    font-weight: bold;
    text-transform: capitalize;
    width: 257px;
    margin: auto;
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
}
::v-deep .btn-primary:hover {
    color: #fff;
    background-color: #f8635f;
    border-color: #f8635f;
    width: 257px
}
.flip-card-back {
    padding: 30px;
}
.learnMore {
    text-align: center;
    border: 1px solid;
    padding: 10px;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    height: 30px;
    line-height: 8px;
}
.learnMore:hover {
    background-color: #f8635f;
}
.learnMore > a {
    color: #fff!important;
}
h2.sponsorHead {
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    text-align: left;
}
p.sponsorText {
    text-align: left;
    color: #fff!important;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    height: 113px;
}
::v-deep p.sponsorText > p {
    color: #fff!important;
    font-size: 12px;
}
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 247px;
    /* height: 255px; */
    /* height: 100%; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    /* background-color: #bbb; */
    color: black;
}

.flip-card-back {
    background-color: #93278f;
    color: white;
    transform: rotateY(180deg);
}
.sponsorImage {
    width: 100%;
    margin-bottom: 30px;
}
.sponsorImage:hover {
    /* background-color: #f8635f; */
}
span.description {
    color: #6C6C6C;
    font-size: 13px;
    margin-top: 30px;
    display: block;
}
p.infoLine {
    color: #6C6C6C;
    font-size: 13px;
}
p.titleHeader {
    color: #6C6C6C;
    font-weight: bold;
    font-size: 13px;
}
h2.howtoBuy {
    color: #6C6C6C;
    font-size: 21px;
    font-weight: bold;
    text-transform: capitalize;
    padding-bottom: 20px;
}
.sunboysImage {
    width: 100%;
    background-image: url(https://updated.sunshinetour.com/wp-content/uploads/2022/08/Screenshot-2022-07-11-at-13.36.26.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 306px;
    background-position: right;
}
.howToBuy {
    background-color: #F2F2F2;
    padding-top: 100px;
    padding-bottom: 100px;
}
.FirstP {
    margin-top: 50px!important;
    margin-bottom: 60px!important;
}
.aboutUsImage {
    width: 100%;
}
.sectionP {
    color: #6C6C6C;
    font-size: 13px;
    margin-bottom: 30px;
    margin-top: 20px;
}
h2.sunboys {
    color: #6C6C6C;
    text-transform: capitalize;
    font-size: 21px;
    font-weight: bold;
}
::v-deep label {
    width: 100%;
}
::v-deep textarea.wpcf7-form-control.wpcf7-textarea {
    width: 100%!important;
    border: 1px solid #ced4da;
}
::v-deep input.wpcf7-form-control.wpcf7-text.wpcf7-validates-as-required {
    width: 100%!important;
    border: 1px solid #ced4da;
    height: 35px;
}
::v-deep div#wpcf7-f34329-o1 {
    margin-top: -15px;
    display: inline-block;
    width: 100%!important;
}
.title{
    color: #6C6C6C;
    text-transform: capitalize;
    font-size: 21px;
    font-weight: bold;
    margin-top: 40px;
   
}
::v-deep  a{
    color: #f8635f!important;
}
.content{
    padding-bottom: 50px;
    padding-top: 30px;
}
::v-deep .wpcf7-submit{
    width: 100%;
    background-color: #f8635f;
    color: #fff;
    border: 0;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
}



@media screen and (max-width: 768px){
    .IframeCode {
        width: 100%;
    }
    .IframeCode > iframe {
        width: 100%;
    }
    .sponsorImage {
        width: 100%;
        margin-bottom: 15px;
    }
    .sponBack.col-sm-6.col-md-6.col-lg-6.col-6 {
        padding-left: 7px;
        padding-right: 7px;
    }
    .SponRow {
        margin: auto;
        text-align: center;
        display: inline-flex;
    }
    ::v-deep .btn-primary {
        color: #fff;
        background-color: #93278f;
        border-color: #93278f;
        width: 100%!important;
        border-radius: 0px;
        font-size: 11px;
        font-weight: bold;
        text-transform: capitalize;
    }
    ::v-deep .btn-primary:hover {
        color: #fff;
        background-color: #f8635f;
        border-color: #f8635f;
        width: 100%!important;
    }  
    .rowTwoContact {
        margin-top: 0px;
        margin-bottom: 60px;
    } 
    .conHeader.col-lg-6 {
        width: 50%;
    }
    .mobileContactBlock {
        margin-left: 15px;
    }
    .title {
        color: #6C6C6C;
        text-transform: capitalize;
        font-size: 21px;
        font-weight: bold;
        margin-top: 40px;
        padding-left: 0px;
       
    }
}
</style>