<template>
  <div>
    <b-container class="newslist" v-if="this.$route.name === 'morenews'">
      <div>
        <b-row>
            <b-col>
                <h3 class="secTitle">
                    All News
                </h3> 
            </b-col>
            <b-col>
                <b-nav-item class="viewAll" to="/news">
                    Back to News
                </b-nav-item>
            </b-col>
        </b-row>
        <IntaNews :cat="'10,8,5,6'" :config="config"/>
      </div>
    </b-container>
  </div>
</template>

<script>
import IntaNews from "@/components/interNews.vue";
export default {
  name: "posts",
  components: {
    IntaNews,
  },
  props: ["config"],
  data() {
    return {
      posts: [],
      featHome: "false",
    };
  },
  metaInfo() {
    return {
      title: 'More News',
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
};
</script>

<style scoped>
::v-deep .viewAll > a {
  color: #fff!important;
} 
.viewAll {
  background: #93278f;
  border: none;
  color: #fff!important;
  width: 150px;
  border: 1px solid #E0E0E0;
  font-weight: bold;
  font-size: 11px;
  padding: 0;
  text-transform: capitalize;
  height: 35px;
  line-height: 6px;
  text-align: center;
  margin-top: 5.3em;
  float: right;
  line-height: 6px!important;
}
.viewAll:hover {
  background: #f8635f;
  border: none;
  color: #fff!important;
  width: 150px;
  border: 1px solid #E0E0E0;
  font-weight: bold;
  font-size: 11px;
  padding: 0;
  text-transform: capitalize;
  height: 35px;
  line-height: 6px;
  text-align: center;
  margin-top: 5.3em;
  float: right;
  line-height: 6px!important;
}
::v-deep h6.card-subtitle.text-muted.mb-2 {
  margin-bottom: 20px !important;
}
::v-deep .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  padding: 0;
  box-shadow: none;
  padding-top: 1.25rem;
}
.seeAllNews {
  text-align: center;
  background: #f8635f;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 300px;
  float: right;
  margin-top: 80px;
}
.seeAllNews:hover {
  text-align: center;
  background: #93278f;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 300px;
  float: right;
  margin-top: 80px;
}
.newslist {
  margin-top: 2em;
  padding-bottom: 50px;
}

.secTitle {
  color: #6C6C6C;
  font-weight: bold;
  font-size: 21px;
  margin-top: 0;
  padding-top: 3em;
  padding-bottom: 1em;
  text-transform: capitalize;
 
}
</style>
