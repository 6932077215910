<template>
  <div>
    <b-container class="newslist">
      <div>
        <b-row>
          <b-col>
            <h3 class="secTitle">
              Blue Label Development Tour Latest news
            </h3>
          </b-col>
        </b-row>
        <IntaNews :cat="'18'" :feat="'true'" :config="config" />
        <div>
            <IntaNews :cat="'18'" :feat="'false'" :config="config"/>
        </div>
        <b-row>
          <b-col>
            <h3 class="secTitle">
              Blue Label Development Tour Video Content
            </h3>
          </b-col>
        </b-row>
        <b-row>
            <b-col lg="4" v-for="(vid, index) in wpInfo[0].acf.blue_label_video_section" :key="index">
                <div class="videoPointer" @click="$bvModal.show('bv-modal-example' + index)">
                  <div v-html="vid.url" class="videoContent">
                  </div>
                </div>
                <div class="">
                  <h6 class="card-subtitle">{{vid.video_name}}</h6>
                </div>
                <b-modal :id="'bv-modal-example' + index" :title="vid.video_name" size="lg" class="modalBox">
                  <div v-html="vid.url">
                  </div>
                </b-modal>
            </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import IntaNews from "@/components/interNews.vue";
export default {
  name: "posts",
  components: {
    IntaNews,
  },
  props: ["config"],
  data() {
    return {
      posts: [],
      wpInfo: [],
      featHome: "false",
    };
  },
  metaInfo() {
    return {
      title: 'Blue Label Challenge',
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: this.description },
      ]
    }
  },
    created() {
    const json = "wp/v2/pages?slug=" + this.$route.name + "&randomadd=" + new Date().getTime();
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.wpInfo = data;
        this.wpInfoTitle = data[0].title.rendered;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
    },
};
</script>

<style scoped>
::v-deep .modal-dialog.modal-lg {
    margin-top: 18em;
}
.card-subtitle {
    color: #6C6C6C !important;
    /* font-weight: 300; */
    font-weight: 700;
    font-size: 13px;
    background-color: #fff;
    margin-top: 13px;
    margin-bottom: 5px !important;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.cardTitle {
    font-weight: 100;
    letter-spacing: -0.5px;
    color: #9aa5ac;
    margin-top: -2px;
    margin-bottom: -5px;
    font-size: 15px;
    color: #f8635f;
    text-transform: capitalize;
}
::v-deep iframe {
    width: 100%;
}
::v-deep .viewAll > a {
  color: #fff!important;
} 
.viewAll {
  background: #93278f;
  border: none;
  color: #fff!important;
  width: 100px;
  border: 1px solid #E0E0E0;
  font-weight: bold;
  font-size: 11px;
  padding: 0;
  text-transform: capitalize;
  height: 35px;
  line-height: 6px;
  text-align: center;
  margin-top: 5.3em;
  float: right;
  line-height: 20px!important;
  display: block;
}
.viewAll:hover {
  background: #f8635f;
  border: none;
  color: #fff!important;
  width: 100px;
  border: 1px solid #E0E0E0;
  font-weight: bold;
  font-size: 11px;
  padding: 0;
  text-transform: capitalize;
  height: 35px;
  line-height: 6px;
  text-align: center;
  margin-top: 5.3em;
  float: right;
  line-height: 20px!important;
  display: block;
}
.seeAllNews {
  text-align: center;
  background: #f8635f;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 300px;
  float: right;
  margin-top: 80px;
}
.seeAllNews:hover {
  text-align: center;
  background: #93278f;
  border: none;
  color: #fff!important;
  border-radius: 0;
  padding: 5px 20px;
  width: 300px;
  float: right;
  margin-top: 80px;
}
.newslist {
  margin-top: 2em;
  padding-bottom: 50px;
}

.secTitle {
  color: #6C6C6C;
  font-weight: bold;
  font-size: 21px;
  margin-top: 0;
  padding-top: 3em;
  padding-bottom: 1em;
  text-transform: capitalize;
 
}
.videoContent {
  pointer-events: none;
}
.videoPointer {
  cursor: pointer;
}
</style>
